import * as React from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface TabHeadProps {
  linkAs: string
  pathname: string
  query?: string
  tabText: string
}

export default function TabHead({ linkAs, pathname, query, tabText }: TabHeadProps) {
  const router = useRouter()

  if (query) {
    return (
      <Container isActive={router.query.type === query}>
        <Link
          as={linkAs}
          href={{
            pathname,
            query: { type: query }
          }}
          passHref
          scroll={false}
        >
          {tabText}
        </Link>
        {router.query.type === query && <ActiveBar />}
      </Container>
    )
  }
  return (
    <Container isActive={router.query.type === undefined}>
      <Link
        as={linkAs}
        href={{
          pathname
        }}
        passHref
        scroll={false}
      >
        {tabText}
      </Link>
      {router.query.type === undefined && <ActiveBar />}
    </Container>
  )
}

const Container = styled.div<{ isActive: boolean }>`
  padding: 16px 8px 0;
  text-align: center;
  ${({ theme }) => theme.fonts.Bold20};
  color: ${({ theme }) => theme.colors.OnSurface5};
  & a {
    color: ${({ theme }) => theme.colors.OnSurface5};
    text-decoration: none;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      a {
        color: ${({ theme }) => theme.colors.OnSurface2};
      }
    `}
`

const ActiveBar = styled.hr`
  box-sizing: content-box;
  padding: 0 8px;
  width: 24px;
  height: 6px;
  background: ${({ theme }) => theme.colors.Primary};
  border-radius: 3px;
  border-style: none;
  margin-bottom: 0;
`
