import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import TextInput from '~/components/atoms/TextInput'

interface Props {
  initialValue?: string
}

export default function SearchBar({ initialValue = '' }: Props) {
  const router = useRouter()
  const [keyword, setKeyword] = useState(initialValue)

  useEffect(() => {
    setKeyword(initialValue)
  }, [initialValue])

  const handleSearch = useCallback(() => {
    router.push({ pathname: `/search`, query: { keyword } })
  }, [keyword])

  const handleChangeInput = useCallback((value: string) => {
    setKeyword(value)
  }, [])

  return (
    <div>
      <TextInput
        value={keyword}
        placeholder='クリエイター名・作品名で検索'
        onEnter={handleSearch}
        onChange={handleChangeInput}
      />
    </div>
  )
}
