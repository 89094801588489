import styled from 'styled-components'
import { CategoryBackgroundColorCss } from '~/components/atoms/Category'
import SectionTab, { ActiveBar } from '~/components/atoms/SectionTab'
import { CategoryMap } from '~/types/consts'
import { useRouter } from 'next/router'
import media from '~/lib/media'

export default function CategoryTabList() {
  const router = useRouter()

  if (router.query.type === 'creators') {
    return null
  }

  const itemCategoryList = Object.entries<string>(CategoryMap).map(([key, label]) => ({
    category: key,
    label: `#${label}`
  }))
  const categoryList = [{ category: undefined, label: 'すべて' }, ...itemCategoryList]

  return (
    <Container>
      <List>
        {categoryList.map(({ label, category }) => (
          <SectionTab
            key={label}
            pathname='/'
            tabText={label}
            query={category !== undefined ? { category } : undefined}
            // すべてはクエリがないときにアクティブ
            isActive={category === undefined ? router.query.category === undefined : undefined}
            activeBar={<CustomActiveBar category={category} />}
          />
        ))}
      </List>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 24px;
  padding-bottom: 8px;
  max-width: 100vw;
  overflow: auto;

  ${media.lessThan('desktop')`
    margin-left: -32px;
    margin-right: -32px;
  `}
`

const List = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;

  ${media.lessThan('desktop')`
    > *:first-child {
      margin-left: 32px;
    }
  `}
`

const CustomActiveBar = styled(ActiveBar)<{ category: string | undefined }>`
  ${({ theme, category }) =>
    category !== undefined
      ? CategoryBackgroundColorCss
      : `
        background: ${theme.colors.Category.All};
      `};
`
