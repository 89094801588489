import styled from 'styled-components'
import axios from '~/lib/axios'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import { useRouter } from 'next/router'
import { IndexPageDataApiResponse } from '~/types/api'
import TabHead from '~/components/atoms/TabHead'
import CategoryTabList from '~/components/molecules/CategoryTabList'
import TopCarousel from '~/components/molecules/TopCarousel'
import LogoHeader from '~/components/molecules/LogoHeader'
import SearchBar from '~/components/molecules/SearchBar'
import CreatorsList from '~/components/organisms/CreatorsList'
import TopFollowingLatestItemList from '~/components/molecules/TopFollowingLatestItemList'
import LatestItemList from '~/components/organisms/LatestItemList'
import WidePageLayout from '~/components/templates/WidePageLayout'
import { useCallback } from 'react'
import useSWR from 'swr'

export default function IndexPage() {
  const router = useRouter()
  const dispatch = useDispatch()
  const account = useSelector(getAccountData)

  const fetcher = useCallback(async <T extends any>(url: string) => {
    return await axios<T>({ url }, { dispatch })
  }, [])

  const { data: pageData } = useSWR<IndexPageDataApiResponse, never>('/top', fetcher)

  return (
    <WidePageLayout>
      <TopPageContainer>
        <LogoHeader />
        <ServiceDescriptionContainer>
          <ServiceDescription>
            Ambass（アンバス）はクリエイターの
            <br />
            デジタル作品を購入・布教できる
            <br />
            ギフティングプラットフォームです🎁
            <br />
            推しの作品をみんなに広めて
            <br />
            クリエイターを応援しよう🙌
          </ServiceDescription>
        </ServiceDescriptionContainer>
        <SearchBarContainer>
          <SearchBar />
        </SearchBarContainer>
        {pageData !== undefined ? (
          <TopCarousel itemList={pageData.carouselItems} />
        ) : (
          <TopCarouselSkeleton />
        )}
        {account !== undefined && account !== null && <TopFollowingLatestItemList />}
        <TabHeadContainer>
          <TabHead linkAs='/' pathname='/' tabText='新着作品' />
          <TabHead linkAs='/?type=creators' pathname='/' query='creators' tabText='クリエイター' />
        </TabHeadContainer>
        <CategoryTabList />
        <TabBody>
          {router.query.type === undefined && <LatestItemList />}
          {router.query.type === 'creators' && pageData !== undefined && <CreatorsList />}
        </TabBody>
      </TopPageContainer>
    </WidePageLayout>
  )
}

const ServiceDescriptionContainer = styled.div`
  margin: 0 -32px 32px;
`

const SearchBarContainer = styled.div`
  width: 327px;
  margin-bottom: 48px;
`

const ServiceDescription = styled.div`
  ${({ theme }) => theme.fonts.Bold14};
  width: 327px;
  margin: 0 auto;
  // 本来ここは24px 32pxのはずだが、サービス説明文が改行されてしまうので一時的に24pxにした
  padding: 24px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.Surface1};
  color: ${({ theme }) => theme.colors.OnSurface3};
  text-align: center;
`

const TopPageContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0 auto;
`

const TopCarouselSkeleton = styled.div`
  height: 351px;
  margin-bottom: 32px;
`

const TabHeadContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  grid-gap: 8px;
  margin-bottom: 32px;
`
const TabBody = styled.div`
  width: 100%;
`
