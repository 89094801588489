import { useState, useEffect } from 'react'
import Link from 'next/link'
import axios from '~/lib/axios'
import { useSelector, useDispatch } from '~/lib/hooks/redux'
import { getAccountData } from '~/state/ducks/accounts/selectors'
import { ItemData } from '~/types'
import { IndexPageFollowingLatestItemsDataApiResponse } from '~/types/api'
import { Forward } from '@material-ui/icons'
import styled from 'styled-components'
import media from '~/lib/media'
import { useMedia } from '~/lib/hooks/media'
import ListItem from '~/components/molecules/ListItem'
import IconButton from '~/components/molecules/IconButton'

type Size = 156 | 176

export default function TopFollowingLatestItemList() {
  const account = useSelector(getAccountData)
  const dispatch = useDispatch()
  const isDesktop = useMedia('desktop')
  const [followingLatestItemsData, setFollowingLatestItemsData] = useState<ItemData[] | undefined>()

  useEffect(() => {
    const fetch = async () => {
      const res = await axios<IndexPageFollowingLatestItemsDataApiResponse>(
        {
          url: '/top/following_latest'
        },
        { dispatch }
      )
      if (res !== undefined) {
        const { followingUsersLatestItems } = res
        setFollowingLatestItemsData(followingUsersLatestItems)
      }
    }

    if (account !== undefined && account !== null) {
      fetch()
    }
  }, [account])

  if (followingLatestItemsData === undefined || followingLatestItemsData.length === 0) {
    return null
  }

  return (
    <Container>
      <Header>フォロー新着作品</Header>
      <ItemList>
        {followingLatestItemsData.map(item => (
          <ListItem item={item} size={isDesktop ? 176 : 156} key={item.itemUid} />
        ))}
        <FollowingLatestPageLink size={isDesktop ? 176 : 156} href='/following/latest' passHref>
          <LinkText>もっと見る</LinkText>
          <StyledIconButton Icon={<Forward />} size={56} />
        </FollowingLatestPageLink>
      </ItemList>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px 0;
`

const Header = styled.div`
  text-align: center;
  ${({ theme }) => theme.fonts.Bold20};
  color: ${({ theme }) => theme.colors.OnSurface2};
  margin-bottom: 24px;
`

const ItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 32px;

  ${media.greaterThan('desktop')`
    display: flex;
    flex-wrap: wrap;
  `}
`
const FollowingLatestPageLink = styled(Link)<{ size: Size }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LinkText = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.OnSurface2};
  ${({ theme }) => theme.fonts.Bold20};
`
const StyledIconButton = styled(IconButton)``
