import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface Props {
  linkAs?: string
  pathname: string
  query?: { [key: string]: string }
  tabText: string
  isActive?: boolean
  activeBar?: React.ReactNode
}

export default function SectionTab({ pathname, query, tabText, isActive, activeBar }: Props) {
  const router = useRouter()
  const queryMatch =
    query !== undefined
      ? Object.entries(query).some(([key, query]) => query === router.query[key])
      : true
  const active = isActive ?? (pathname === router.pathname && queryMatch)

  return (
    <Container>
      <StyledLink
        href={{
          pathname,
          query
        }}
        passHref
        scroll={false}
        $active={active}
      >
        {tabText}
      </StyledLink>
      {active && (activeBar !== undefined ? <>{activeBar}</> : <ActiveBar />)}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 12px 8px 0;
`

const StyledLink = styled(Link)<{ $active: boolean }>`
  ${({ theme }) => theme.fonts.Bold16};
  color: ${({ $active, theme }) => ($active ? theme.colors.OnSurface2 : theme.colors.OnSurface5)};
  text-decoration: none;
`

export const ActiveBar = styled.hr`
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.Primary};
  border-radius: 4px;
  border-style: none;
  margin: 4px 0 0;
`
