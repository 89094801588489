import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Slider, { Settings } from 'react-slick'
import Thumbnail from '~/components/molecules/Thumbnail'
import { ItemData } from '~/types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface Props {
  itemList: ItemData[]
}

export default function TopCarousel({ itemList }: Props) {
  const ref = useRef<Slider>(null)

  const settings: Settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: true,
    variableWidth: true
  }

  // 初回だけさらに3秒待機する
  useEffect(() => {
    setTimeout(() => {
      if (ref.current !== null) {
        ref.current.slickPlay()
      }
    }, 3000)
  }, [])

  return (
    <div>
      <TopCarouselContainer>
        <Container itemCount={itemList.length}>
          <Slider {...settings} ref={ref}>
            {itemList.map((item, index) => (
              <SlickContainer key={item.itemUid}>
                <Thumbnail
                  {...item}
                  size={327}
                  displayInfo={true}
                  alt='sample'
                  priority={index === 0}
                />
              </SlickContainer>
            ))}
          </Slider>
        </Container>
      </TopCarouselContainer>
      <CarouselSkeleton />
    </div>
  )
}

const TopCarouselContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
`

const CarouselSkeleton = styled.div`
  height: ${327 + 50}px;
`

const Container = styled.div<{ itemCount?: number }>`
  margin-bottom: 32px;

  .slick-dots {
    margin-bottom: 0;
  }

  .slick-track {
    // なぜかライブラリのwidth計算がおかしくて小さいときがあるのでmin-widthをセットする
    min-width: ${({ itemCount = 0 }) => (327 + 16) * itemCount}px;
  }

  // TODO: ここ以下はItemThumbnailと同じなので共通化したい
  .slick-dots {
    margin: 0;
    line-height: 0;
    bottom: -32px;
  }

  .slick-dots > li {
    width: 24px;
    height: 24px;
    margin: 0;
    padding: 0;
  }

  .slick-dots > li > button::before {
    padding: 0;
    font-size: 0;
    width: 0;
    height: 0;
  }

  .slick-dots > li > button {
    box-sizing: content-box;
    padding: 8px;
    width: 8px;
    height: 8px;
    background: none;

    &::before {
      position: static;
      display: block;
      border-radius: 4px;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.colors.OnSurface5};
    }
  }
  .slick-dots > li.slick-active > button {
    &::before {
      border-radius: 4px;
      width: 8px;
      height: 8px;
      background-color: ${({ theme }) => theme.semantics.Link};
    }
  }
  .slick-slide {
    margin: 0;
  }
`

const SlickContainer = styled.div`
  vertical-align: top;
  margin: 0 8px;
`
