import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Clear } from '@material-ui/icons'

interface Props {
  placeholder?: string
  value: string
  type?: 'text' | 'number' | 'password'
  multiple: boolean
  onChange(value: string): void
  onEnter?(value: string): void
}

function TextInput({ placeholder, value, type = 'text', multiple, onChange, onEnter }: Props) {
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.nativeEvent.isComposing || e.key !== 'Enter' || onEnter === undefined) {
        return
      }

      onEnter(value)
    },
    [onEnter]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(e.currentTarget.value)
    },
    [onChange]
  )

  const handleClear = useCallback(
    (_e: React.MouseEvent<HTMLImageElement>) => {
      onChange('')
    },
    [onChange]
  )

  return (
    <Container>
      {multiple ? (
        <TextArea
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      )}
      {value !== '' && (
        <ClearIconWrapper onClick={handleClear}>
          <Clear style={{ fontSize: 16 }} />
        </ClearIconWrapper>
      )}
    </Container>
  )
}

export default TextInput

interface TextAreaProps {
  value: string
  placeholder?: string
  onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void
  onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>): void
}
function TextArea({ value, placeholder, onChange, onKeyDown }: TextAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>()
  const [height, setHeight] = useState(24)

  useEffect(() => {
    setHeight(Math.max(textareaRef.current.scrollHeight, 24))
  }, [value])

  return (
    <StyledTextArea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      rows={1}
      $height={height}
      ref={textareaRef}
    />
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 20px;
  grid-gap: 16px;
`

const InputCss = css`
  ${({ theme }) => theme.fonts.Bold20};
  width: 100%;
  border-radius: 4px;
  padding: 0;
  color: ${({ theme }) => theme.colors.OnSurface2};
  border: 0;
  outline: 0;
  background: none;
`

const StyledInput = styled.input`
  ${InputCss};
`

const StyledTextArea = styled.textarea<{ $height: number }>`
  ${InputCss};
  resize: none;
  min-height: 24px;
  height: ${({ $height }) => `${$height}px`};
  word-break: break-all;
`

const ClearIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
`
