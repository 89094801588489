import React from 'react'
import styled from 'styled-components'
import TextInputBody from '~/components/atoms/TextInputBody'

interface Props {
  placeholder?: string
  title?: string
  value: string
  type?: 'text' | 'number' | 'password'
  multiple?: boolean
  error?: string | string[]
  onChange(value: string): void
  onEnter?(value: string): void
}

function TextInput({
  placeholder,
  value,
  type = 'text',
  multiple = false,
  title,
  error: rawError,
  onChange,
  onEnter
}: Props) {
  const error =
    rawError === undefined || typeof rawError === 'string'
      ? rawError
      : rawError.length > 0
      ? rawError[0]
      : ''
  const hasTitle = title !== undefined && title !== ''
  const hasError = error !== undefined && error !== ''

  return (
    <Container>
      {hasTitle && <Title>{title}</Title>}

      <InputContainer>
        <TextInputBody
          type={type}
          multiple={multiple}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onEnter={onEnter}
        />

        <Border hasError={hasError} />
      </InputContainer>

      {hasError && <ErrorText>{error}</ErrorText>}
    </Container>
  )
}

export default TextInput

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-auto-rows: auto;
  grid-gap: 8px;
`

const InputContainer = styled.div`
  display: grid;
  align-items: center;
  grid-auto-rows: auto;
  grid-gap: 4px;
`

const Title = styled.p`
  ${({ theme }) => theme.fonts.Bold14};
  margin: 0;
  color: ${({ theme }) => theme.colors.OnSurface5};
`

const Border = styled.hr<{ hasError: boolean }>`
  width: 100%;
  height: 4px;
  border: 0;
  margin: 0;
  border-radius: 2px;
  background-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.Assertive : theme.colors.OnSurface1};
`

const ErrorText = styled.p`
  ${({ theme }) => theme.fonts.Bold16};
  margin: 0;
  color: ${({ theme }) => theme.colors.Assertive};
`
