import React from 'react'
import Link from 'next/link'
import { CategoryMap } from '~/types/consts'
import styled, { css } from 'styled-components'

interface Props {
  category: keyof typeof CategoryMap
  size?: number
}

function Category({ category, size = 14 }: Props) {
  const label = CategoryMap[category] ?? '未設定'
  return (
    <Link
      href={{
        pathname: `/`,
        query: {
          category
        }
      }}
      passHref
    >
      <Label size={size} category={category}>
        #{label}
      </Label>
    </Link>
  )
}

export default Category

export const CategoryColorCss = css<{
  category: keyof typeof CategoryMap
}>`
  color: ${({ category, theme }) =>
    category === 'illustration'
      ? theme.colors.Category.Illustration
      : category === 'manga'
      ? theme.colors.Category.Manga
      : category === 'novel'
      ? theme.colors.Category.Novel
      : category === 'music'
      ? theme.colors.Category.Music
      : category === 'voice'
      ? theme.colors.Category.Voice
      : category === 'idol'
      ? theme.colors.Category.Idol
      : category === 'cosplay'
      ? theme.colors.Category.Cosplay
      : category === 'photo'
      ? theme.colors.Category.Photo
      : category === 'video'
      ? theme.colors.Category.Video
      : category === 'game'
      ? theme.colors.Category.Game
      : category === 'trpg'
      ? theme.colors.Category.Trpg
      : theme.colors.Category.Other};
`

export const CategoryBackgroundColorCss = css<{
  category: keyof typeof CategoryMap
}>`
  background: ${({ category, theme }) =>
    category === 'illustration'
      ? theme.colors.Category.Illustration
      : category === 'manga'
      ? theme.colors.Category.Manga
      : category === 'novel'
      ? theme.colors.Category.Novel
      : category === 'music'
      ? theme.colors.Category.Music
      : category === 'voice'
      ? theme.colors.Category.Voice
      : category === 'idol'
      ? theme.colors.Category.Idol
      : category === 'cosplay'
      ? theme.colors.Category.Cosplay
      : category === 'photo'
      ? theme.colors.Category.Photo
      : category === 'video'
      ? theme.colors.Category.Video
      : category === 'game'
      ? theme.colors.Category.Game
      : category === 'trpg'
      ? theme.colors.Category.Trpg
      : theme.colors.Category.Other};
`

const Label = styled.span<{ size: number; category: keyof typeof CategoryMap }>`
  ${({ theme, size }) => (size === 14 ? theme.fonts.Bold14 : theme.fonts.Bold20)};
  ${CategoryColorCss};
`
