import { useCallback } from 'react'
import useSWR from 'swr'
import styled from 'styled-components'
import UserIcon from '~/components/atoms/UserIcon'
import axios from '~/lib/axios'
import media from '~/lib/media'
import { IndexPageCreatorsDataApiResponse } from '~/types/api'
import { useDispatch } from '~/lib/hooks/redux'

export default function CreatorsList() {
  const dispatch = useDispatch()

  const fetcher = useCallback(async <T extends any>(url: string) => {
    return await axios<T>({ url }, { dispatch })
  }, [])

  const { data: creatorsData } = useSWR<IndexPageCreatorsDataApiResponse, never>(
    '/top/creators',
    fetcher
  )

  if (creatorsData === undefined) {
    return null
  }

  return (
    <Container>
      {creatorsData.creators.map(creator => (
        <div key={creator.userId}>
          <CoverImage coverImage={creator.coverImageUrl} />
          <UserIconContainer>
            <UserIconWrapper>
              <UserIcon
                size={96}
                url={creator.profileImageUrl}
                userId={creator.userId}
                name={creator.name}
              />
            </UserIconWrapper>
            <UserName>{creator.name}</UserName>
          </UserIconContainer>
        </div>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 24px;

  ${media.greaterThan('desktop')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.greaterThan('desktopWide')`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const CoverImage = styled.div<{ coverImage?: string }>`
  width: 100%;
  height: 156px;
  border-radius: 16px 16px 16px 0px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${({ coverImage }) =>
    coverImage ? `background-image: url('${coverImage}')` : 'background-color: #ccc'};
`

const UserIconContainer = styled.div`
  display: grid;
  grid-template-areas:
    'userIcon _'
    'userIcon userName';
  grid-template-columns: auto 1fr;
  grid-auto-rows: 1fr 1fr;
  grid-gap: 8px;
  margin-top: -48px;
`

const UserIconWrapper = styled.div`
  grid-area: userIcon;
`

const UserName = styled.p`
  ${({ theme }) => theme.fonts.Bold16};
  grid-area: userName;
  margin: 0;
  color: ${({ theme }) => theme.colors.OnSurface2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
