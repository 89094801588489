import React from 'react'
import styled from 'styled-components'
import media from '~/lib/media'
import { calculateColumns } from '~/types/consts'

export default function WidePageLayout({ children }: { children: React.ReactNode }) {
  return <Container>{children}</Container>
}

const Container = styled.div`
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 24px;
  max-width: 640px;
  min-width: 327px;

  ${media.greaterThan('desktop')`
    max-width: ${calculateColumns(8)}px;
  `}

  ${media.greaterThan('desktopWide')`
    max-width: ${calculateColumns(12)}px;
    padding: 0 72px;
  `}
`
