import styled from 'styled-components'
import { useCallback } from 'react'
import UserIcon from '~/components/atoms/UserIcon'
import Category from '~/components/atoms/Category'
import Thumbnail from '~/components/molecules/Thumbnail'
import { ItemData, LibraryReceivedItemData } from '~/types'
import UserName from '~/components/atoms/UserName'

type Size = 156 | 176

interface Props {
  size: Size
  item: ItemData | LibraryReceivedItemData
  showUser?: boolean
}

export default function ListItem({ item, size, showUser = true }: Props) {
  const isReceivedItem = useCallback(
    (item: ItemData | LibraryReceivedItemData): item is LibraryReceivedItemData => {
      return 'fromUser' in item
    },
    [item]
  )

  return (
    <Container size={size}>
      <Thumbnail {...item} size={size} alt={item.title} />
      <MetaContainer>
        <CategoryContainer showUser={showUser}>
          <div>
            <Category category={item.category} />
          </div>
          {showUser && (
            <>
              <UserNameContainer>
                <UserName name={item.user.name} userId={item.user.userId} />
              </UserNameContainer>
              <UserContainer>
                <UserIcon
                  size={32}
                  url={item.user.profileImageUrl}
                  userId={item.user.userId}
                  name={item.user.name}
                />
              </UserContainer>
            </>
          )}
        </CategoryContainer>
        <Title>{item.title}</Title>
        {/* TODO: TypeGuardの結果をメモ化したい */}
        {isReceivedItem(item) && <FromUser>{item.fromUser.name}さんから受け取り</FromUser>}
      </MetaContainer>
    </Container>
  )
}

const Container = styled.div<{ size: Size }>`
  width: ${({ size }) => `${size}px`};
`

const CategoryContainer = styled.div<{ showUser: boolean }>`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  grid-template-areas: ${({ showUser }) =>
    showUser
      ? `
        'category userIcon'
        'userName userIcon'
      `
      : `
      'category'
    `};
`

const MetaContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 8px;
  margin-top: 8px;
  margin-left: 8px;
`

const Title = styled.div`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface2};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-all;
`
const UserContainer = styled.div`
  display: flex;
  align-items: center;
  grid-area: userIcon;
`
const UserNameContainer = styled.div`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface5};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  grid-area: userName;
`
const FromUser = styled.p`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface5};
`
