import styled from 'styled-components'
import media from '~/lib/media'
import axios from '~/lib/axios'
import { useDispatch } from '~/lib/hooks/redux'
import { useMedia } from '~/lib/hooks/media'
import { useRouter } from 'next/router'
import { IndexPageLatestItemsDataApiResponse } from '~/types/api'
import ListItem from '~/components/molecules/ListItem'
import LoadingSpinnerBody from '~/components/atoms/LoadingSpinner/LoadingSpinnerBody'
import { useState, useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import useSWRInfinite from 'swr/infinite'

export default function LatestItemList() {
  const router = useRouter()
  const dispatch = useDispatch()
  const [loadEnd, setLoadEnd] = useState<boolean>(false)
  const isDesktop = useMedia('desktop')

  const fetcher = useCallback(
    async <T extends any>(url: string) => {
      // Next.jsの仕様で、hidrateされるまではrouter.queryが取得できない
      // categoryありのときにqueryなしAPIとありで2回叩いてしまうのを防いでいる
      if (!router.isReady) {
        return undefined
      }

      return await axios<T>({ url }, { dispatch })
    },
    [router]
  )

  const { data, size, setSize } = useSWRInfinite<IndexPageLatestItemsDataApiResponse, never>(
    (pageIndex: number, previousPageData: IndexPageLatestItemsDataApiResponse) => {
      // 最後に到達した
      if (previousPageData && !previousPageData.hasNext) {
        return null
      }

      const url = new URL('/top/latest', location.origin)
      if (router.query.category !== undefined) {
        url.searchParams.append('category', `${router.query.category}`)
      }
      url.searchParams.append('page', `${pageIndex + 1}`)

      return url.pathname + url.search
    },
    fetcher,
    {
      // なぜか1ページ目をずっと読み込み続けるのでOFF
      revalidateFirstPage: false,
      // 現状、ランダムに並び替えて取得するので画面が何度も変わってしまうため再取得はしないようにしている
      revalidateIfStale: false
    }
  )

  useEffect(() => {
    if (data !== undefined && data.some(d => d !== undefined && !d.hasNext)) {
      // 最後まで読み込みしていた場合はLoadingSpinnerは非表示にする
      setLoadEnd(true)
    } else {
      // タブ切り替えなどを行ったらLoadingSpinnerの表示をリセット
      setLoadEnd(false)
    }
  }, [data, router])

  const {
    ref,
    inView,
    entry: _
  } = useInView({
    threshold: 0
  })

  // LoadMoreコンポーネントが画面内に入ったら次の読み込みを行う
  useEffect(() => {
    if (inView) {
      setSize(size + 1)
    }
  }, [inView])

  if (!data) {
    return (
      <Placeholder>
        <LoadingSpinnerBody />
      </Placeholder>
    )
  }

  return (
    <>
      {data.length === 0 ? (
        <Placeholder>
          <NotFound>データがありません</NotFound>
        </Placeholder>
      ) : (
        <ItemList>
          {data.map((latest, _index) => {
            if (latest === undefined) {
              return null
            }
            return latest.latestItems.map(item => (
              <ListItem item={item} size={isDesktop ? 176 : 156} key={item.itemUid} />
            ))
          })}
        </ItemList>
      )}
      {!loadEnd && (
        <LoadMore ref={ref}>
          <LoadingSpinnerBody />
        </LoadMore>
      )}
    </>
  )
}

const ItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 32px;

  ${media.greaterThan('desktop')`
    display: flex;
    flex-wrap: wrap;
  `}
`

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
`

const NotFound = styled.p`
  ${({ theme }) => theme.fonts.Bold14};
  color: ${({ theme }) => theme.colors.OnSurface5};
`

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  margin: 72px auto;
`
